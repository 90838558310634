import { SET_FAVORITE, SET_GROUPS } from './actionTypes'
import { GroupsState, BuilderActions } from './types'

const groupsInitialState: GroupsState = {
  groups: [],
}

const groupsReducer = (
  state = groupsInitialState,
  action: BuilderActions
): GroupsState => {
  switch (action.type) {
    case SET_GROUPS:
      state.groups = [...action.groups]
      return { ...state }
    case SET_FAVORITE:
      state.groups = state.groups.map(group => ({
        ...group,
        isFavorite: group.title === action.title ? !group.isFavorite : false,
      }))
      return { ...state }
    default:
      return state
  }
}

export default groupsReducer
