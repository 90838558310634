import {
  SET_HOSPITAL,
  SET_IS_HOSPITAL,
  SET_IS_WEATHER,
  SET_LOCATIONS,
} from './actionTypes'
import { LogisticsState, BuilderActions } from './types'

const logisticsInitialState: LogisticsState = {
  hospital: '',
  isHospital: false,
  isWeather: false,
  locations: [],
}

const logisticsReducer = (
  state = logisticsInitialState,
  action: BuilderActions
): LogisticsState => {
  switch (action.type) {
    case SET_HOSPITAL:
      return { ...state, hospital: action.hospital }
    case SET_IS_WEATHER:
      return { ...state, isWeather: action.isWeather }
    case SET_IS_HOSPITAL:
      return { ...state, isHospital: action.isHospital }
    case SET_LOCATIONS:
      return { ...state, locations: action.locations }
    default:
      return state
  }
}

export default logisticsReducer
