const THEME_FONT_FAMILY = '"Avenir", "Roboto", "Helvetica", "Arial", sans-serif'
const THEME_BORDER_RADIUS = 10
const COLOR_PRIMARY_MAIN = '#265A88'
const COLOR_PRIMARY_LIGHT = '#2A7BC0'
const COLOR_INFO_MAIN = '#0971E5'
const COLOR_ERROR_MAIN = '#FF3B30'
const COLOR_SUCCESS_MAIN = '#38C171'
const COLOR_TEXT_PRIMARY = '#1F1F1F'
const COLOR_TEXT_SECONDARY = '#545454'
const COLOR_TEXT_DISABLED = '#AFAFAF'
const COLOR_SECONDARY_DARK = '#517B9F'
const COLOR_SECONDARY_MAIN = '#7C9CB7'
const COLOR_SECONDARY_LIGHT = '#A8BDCF'

const DRAWER_WIDTH = '538px'

export {
  THEME_FONT_FAMILY,
  THEME_BORDER_RADIUS,
  COLOR_TEXT_PRIMARY,
  COLOR_TEXT_SECONDARY,
  COLOR_TEXT_DISABLED,
  COLOR_PRIMARY_MAIN,
  COLOR_PRIMARY_LIGHT,
  COLOR_INFO_MAIN,
  COLOR_ERROR_MAIN,
  COLOR_SUCCESS_MAIN,
  COLOR_SECONDARY_DARK,
  COLOR_SECONDARY_MAIN,
  COLOR_SECONDARY_LIGHT,
  DRAWER_WIDTH,
}
