const PREFIX = 'CALLSHEET/'

export const SET_CALLSHEET_TITLE = `${PREFIX}SET_CALLSHEET_TITLE` as const
export const SET_EVENT_DATE = `${PREFIX}SET_EVENT_DATE` as const
export const SET_GENERAL_CREW_CALL_TIME =
  `${PREFIX}SET_GENERAL_CREW_CALL_TIME` as const
export const SET_COMPANY = `${PREFIX}SET_COMPANY` as const
export const SET_MEMBERS = `${PREFIX}SET_MEMBERS` as const
export const SET_LOGO = `${PREFIX}SET_LOGO` as const
export const SET_TITLE = `${PREFIX}SET_TITLE` as const
export const SET_WEBSITE = `${PREFIX}SET_WEBSITE` as const
export const SET_ADDRESS = `${PREFIX}SET_ADDRESS` as const
export const SET_IS_SAVE_TO_CATALOG = `${PREFIX}SET_IS_SAVE_TO_CATALOG` as const
