/* eslint prefer-const: ["error", { ignoreReadBeforeAssign: true }] */

import React, { FC, useState } from 'react'
import {
  Grid,
  Container,
  Box,
  Typography,
  TextField,
  Button,
} from '@mui/material'
import MailchimpSubscribe from 'react-mailchimp-subscribe'
import useStyles from './style'
import ComingSoonImg from '../../../assets/images/comingsoon.png'
import ComingSoonMobileImg from '../../../assets/images/comingsoon-sm.png'

type MailChimpFormProps = {
  status: any
  message: any
  classes: any
  onValidated: any
}

const MailChimpForm: FC<MailChimpFormProps> = ({
  status,
  message,
  classes,
  onValidated,
}) => {
  const [email, setEmail] = useState<string | null>('')
  const submit = () => {
    if (email !== '') {
      onValidated({
        EMAIL: email,
      })
    }
  }

  return (
    <>
      <Grid item xs={8}>
        <Box mt={9}>
          <TextField
            fullWidth
            name='email'
            variant='outlined'
            placeholder='Enter your Email'
            className={classes.input}
            InputProps={{
              className: classes.input,
            }}
            value={email}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(event.target.value)
            }}
          />
        </Box>
      </Grid>
      <Grid item xs={4} className={classes.formContainerButton}>
        <Button
          fullWidth
          variant='contained'
          onClick={submit}
          sx={{ fontSize: { xs: 14, md: 14, lg: 18 } }}
          style={{
            borderRadius: 12,
            height: 62,
            backgroundColor: '#0971E5',
            fontFamily: 'Avenir',
          }}
        >
          {status === null && 'Join the Waitlist'}
          {status === 'sending' && 'Sending..'}
          {status === 'error' && message}
          {status === 'success' && 'Thank you!'}
        </Button>
      </Grid>
    </>
  )
}

const MainBody: FC = () => {
  const classes = useStyles()
  const formUrl =
    'https://app.us6.list-manage.com/subscribe/post?u=91372cdc2bbf3e01c3ac7223d&id=6df59b3722&f_id=005008e3f0'
  return (
    <>
      <Container className={classes.comingSoonContainer}>
        <p className={classes.comingSoonPtag}>Coming Soon</p>
        <Box
          sx={{ fontSize: { xs: '36px', md: '72px', lg: '72px' } }}
          className={classes.getNotified}
        >
          Get Notified
          <br />
          When we Launch
        </Box>
        <p className={classes.getEarlyAccessMsg}>
          Get Early Access to our platform and receive updates on new releases!
        </p>
      </Container>
      <Grid container xs={12} sm={6} className={classes.formContainer}>
        <MailchimpSubscribe
          url={formUrl}
          render={({ subscribe, status, message }) => (
            <MailChimpForm
              status={status}
              message={message}
              classes={classes}
              onValidated={(formData: any) => subscribe(formData)}
            />
          )}
        />
      </Grid>
      <Box sx={{ display: { xs: 'none', md: 'flex', lg: 'flex' } }}>
        <img src={ComingSoonImg} alt='Coming soon' width='100%' />
      </Box>
      <Box sx={{ display: { xs: 'flex', md: 'none', lg: 'none' } }}>
        <img src={ComingSoonMobileImg} alt='Coming soon' width='100%' />
      </Box>
    </>
  )
}

export default MainBody
