import { combineReducers } from 'redux'
import builderReducer from './Builder/reducer'
import contactsReducer from './Contacts/reducer'
// import companiesReducer from './Companies/reducer'
import createCallsheetReducer from './CreateCallsheet/reducer'
import groupsReducer from './Groups/reducer'
import logisticsReducer from './Logistics/reducer'

const rootReducer = combineReducers({
  builderState: builderReducer,
  contactsState: contactsReducer,
  logisticsState: logisticsReducer,
  groupsState: groupsReducer,
  // companiesState: companiesReducer,
  createCallsheetState: createCallsheetReducer,
})

export default rootReducer
