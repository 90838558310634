import { STEPS } from '../../components/BuilderPage/constants'
import {
  SET_COMPANY_TYPE,
  SET_IS_COMPANY_PANEL,
  SET_IS_LOCATION_PANEL,
  SET_IS_MEMBERS_PANEL,
  SET_IS_USER_GROUP_PANEL,
  SET_STEP,
} from './actionTypes'
import { BuilderActions, BuilderState } from './types'

const builderInitialState: BuilderState = {
  step: STEPS.overview,
  isCompanyPanel: false,
  isMembersPanel: false,
  isLocationPanel: false,
  isUserGroupPanel: false,
  companyType: '',
}

const builderReducer = (
  state = builderInitialState,
  action: BuilderActions
): BuilderState => {
  switch (action.type) {
    case SET_STEP:
      return { ...state, step: action.step }
    case SET_IS_COMPANY_PANEL:
      return { ...state, isCompanyPanel: action.isCompanyPanel }
    case SET_IS_MEMBERS_PANEL:
      return { ...state, isMembersPanel: action.isMembersPanel }
    case SET_IS_LOCATION_PANEL:
      return { ...state, isLocationPanel: action.isLocationPanel }
    case SET_IS_USER_GROUP_PANEL:
      return { ...state, isUserGroupPanel: action.isUserGroupPanel }
    case SET_COMPANY_TYPE:
      return { ...state, companyType: action.companyType }
    default:
      return state
  }
}

export default builderReducer
