import produce from 'immer'

import { INITIAL_COMPANY, INITIAL_MEMBERS } from './constants'
import {
  SET_CALLSHEET_TITLE,
  SET_EVENT_DATE,
  SET_GENERAL_CREW_CALL_TIME,
  SET_COMPANY,
  SET_MEMBERS,
  SET_LOGO,
  SET_TITLE,
  SET_WEBSITE,
  SET_ADDRESS,
  SET_IS_SAVE_TO_CATALOG,
} from './actionTypes'
import { CreateCallsheetActions, CreateCallsheetState } from './types'

const CallsheetInitialState: CreateCallsheetState = {
  callsheetTitle: '',
  eventDate: null,
  generalCrewCallTime: '',
  companies: {
    production: {
      ...INITIAL_COMPANY,
      ...INITIAL_MEMBERS,
    },
    client: INITIAL_COMPANY,
  },
}

const createCallsheetReducer = produce(
  (draft = CallsheetInitialState, action: CreateCallsheetActions) => {
    switch (action.type) {
      case SET_CALLSHEET_TITLE:
        draft.callsheetTitle = action.callsheetTitle
        break
      case SET_EVENT_DATE:
        draft.eventDate = action.eventDate
        break
      case SET_GENERAL_CREW_CALL_TIME:
        draft.generalCrewCallTime = action.generalCrewCallTime
        break
      case SET_COMPANY:
        draft.companies[action.companyType] = action.company
        break
      case SET_MEMBERS:
        draft.companies.production.members = action.members
        break
      case SET_LOGO:
        draft.companies[action.companyType].logo = action.logo
        break
      case SET_TITLE:
        draft.companies[action.companyType].title = action.title
        break
      case SET_WEBSITE:
        draft.companies[action.companyType].website = action.website
        break
      case SET_ADDRESS:
        draft.companies[action.companyType].address = action.address
        break
      case SET_IS_SAVE_TO_CATALOG:
        draft.companies[action.companyType].isSaveToCatalog =
          action.isSaveToCatalog
        break
      default:
        return draft
    }
  }
)

export default createCallsheetReducer
