import React, { FC } from 'react'
import { Grid } from '@mui/material'
import Logo from '../../../assets/images/logo.svg'
import style from './style'

const Header: FC = () => {
  return (
    <Grid style={style.header}>
      <img src={Logo} alt='Logo'/>
    </Grid>
  )
}

export default Header
