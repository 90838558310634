import React, { FC } from 'react'
import Header from './Header'
import Footer from './Footer'
import MainBody from './MainBody'

const ComingSoonPage: FC = () => {
  return (
    <>
      <Header />
      <MainBody />
      <Footer />
    </>
  )
}

export default ComingSoonPage
