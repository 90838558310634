import React, { FC } from 'react'
import ComingSoonPage from './components/ComingSoonPage'

import './App.css'

const App: FC = () => (
  <div className='app'>
    <ComingSoonPage />
  </div>
)

export default App
