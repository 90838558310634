import { contactList } from '../../mockData/contacts'
import { Contact, ContactsActions, ContactsState } from './types'

const contactsInitialState: ContactsState = {
  contacts: contactList as Contact[],
}

const ContactsReducer = (
  state = contactsInitialState,
  action: ContactsActions
): ContactsState => {
  return state
}

export default ContactsReducer
