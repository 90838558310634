import React, { FC } from 'react'
import useStyles from './style'

const Footer: FC = () => {
  const classes = useStyles()
  return (
    <footer>
      <p className={classes.footer}>&copy; 2023 callsheets.com</p>
    </footer>
  )
}

export default Footer
