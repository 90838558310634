export const INITIAL_MEMBERS = {
  members: [],
}

export const INITIAL_COMPANY = {
  logo: '',
  title: '',
  website: {
    url: '',
    showOnCallsheet: false,
  },
  address: {
    location: null,
    showOnCallsheet: false,
  },
  isSaveToCatalog: true,
}
