import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  comingSoonContainer: {
    display: 'flex',
    flex: 1,
    flexDirection: 'column',
    width: '100%',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: 50,
    textAlign: 'center',
  },
  comingSoonPtag: {
    fontFamily: 'Avenir',
    color: '#0971E5',
    fontStyle: 'normal',
    fontWeight: 500,
    fontSize: 18,
    lineHeight: '110%',
    letterSpacing: -0.4,
  },
  getNotified: {
    fontFamily: 'Avenir',
    fontStyle: 'normal',
    fontWeight: 550,
    lineHeight: '110%',
    letterSpacing: 3,
    color: '#1F1F1F',
    flexGrow: 0,
    marginTop: 20,
    marginBottom: 0,
  },
  getEarlyAccessMsg: {
    fontFamily: 'Avenir',
    margin: '12px 0 32px 0 !important',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: 18,
  },
  formContainer: {
    display: 'flex',
    flex: 1,
    justifyContent: 'center',
    alignItems: 'center',
    alignSelf: 'center',
    textAlign: 'center',
    margin: 'auto',
    padding: '8px 0px',
    borderRadius: 12,
    backgroundColor: '#efefef',
  },
  formContainerButton: {
    marginLeft: -16,
  },
  input: {
    backgroundColor: '#ffffff',
    borderRadius: 12,
    height: 62,
    borderWidth: 0,
    borderTopRightRadius: 0,
    borderBottomRightRadius: 0,
  },
  sending: {
    fontFamily: 'Avenir',
    fontSize: 12,
    fontWeight: 'bold',
  },
  error: {
    fontFamily: 'Avenir',
    fontSize: 12,
    fontWeight: 'bold',
    color: 'red',
  },
  success: {
    fontFamily: 'Avenir',
    fontSize: 12,
    fontWeight: 'bold',
    color: 'green',
  },
}))

export default useStyles
