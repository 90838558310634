import { makeStyles } from '@mui/styles'

const useStyles = makeStyles(theme => ({
  footer: {
    fontFamily: 'Avenir',
    fontSize: 12,
    textAlign: 'center',
  },
}))

export default useStyles
